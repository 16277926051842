import React from "react";
import axiosInterceptor from "../../../utils/axiosInterceptor.ts";
// import { dateToString, stringToDate } from "../../../utils/formatter";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import {Form, Modal} from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import FormInput, { DateInput } from "../../FormInput";
import { Row, Col} from 'react-bootstrap'
// import ExternalCourse, { CreateCourse } from "./ExternalCourses";


export default function Education({ eduList, setEducation }) {

  function EduItem({ item }) {
    const { school, degree, aos, minor, gpa, start_date, end_date } = item;
    const startDateAsMonthYear = new Date(start_date).toLocaleString('default', { month: 'long', year: 'numeric' });
    const endDateAsMonthYear = new Date(end_date).toLocaleString('default', { month: 'long', year: 'numeric' }) ?? "Present";
    const date = startDateAsMonthYear +" – "+ endDateAsMonthYear

    if (typeof school === 'undefined') {
      console.log("Item no longer exists")
      return;
    }
    else {
      return (
        <div className="flex flex-row items-center justify-between mb-3">
          <div className="flex flex-row gap-x-4 items-start">
            <div className="h-16 rounded-lg w-16 bg-[#D9D9D9]" />
            <div className="flex flex-col gap-y-0.5 h-min">
              <p className="font-semibold text-black text-lg">{school}</p>
              <p className="text-black font-medium">{degree} - {aos}</p>
              <p className="text-gray-700">{date}</p>
              <p className="text-gray-700">GPA: {gpa}</p>
            </div>
          </div>
        </div>
      );
    }
  }

  const sortedEduList = eduList.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

  return (
    <div className="-mb-3">
      {sortedEduList.map((item, i) => {
        return <EduItem key={i} item={item} />;
      })}
    </div>
  );
}


export function CreateEdu({ eduList, setEducation }) {
  
  const BLANK_DATA = {
    school: "",
    degree: "BS",
    aos: "",
    minor: "",
    gpa: "",
    start_date: "",
    end_date: "",
  };

  const options = [
    'B.S.', 'B.A.', 'M.S.', 'M.A.', 'M.B.A.', 'M.Eng.',  'Ph.D.',
  ];

  var start_info = BLANK_DATA

  if (eduList != null && JSON.stringify(eduList) !== "{}"){start_info = eduList}
  
  const [education, setEdu] = React.useState(start_info);

  console.log(start_info)
  function updateEducationItem(index, field, value) {
    // Create a copy of the eduList
    const updatedList = [...education];

    // Update the specified item with the new values
    updatedList[index] = {
      ...updatedList[index],
      [field]: value,
    };
    // Update the eduList state
    setEdu(updatedList);
  }

  const handleChange = function (e, index) {
      updateEducationItem(index, e.target.name, e.target.value)
  };

  function handleRemove(itemToRemove) {
    if('id' in itemToRemove){
      try{
        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
        axiosInterceptor.delete(`/delete_edu_entry/`+itemToRemove.id+'/', itemToRemove, {
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
              }
          });
      }
      catch(error){
        console.log('Error: ', error)
      }
    }
    const updateList = education.filter(item => item !== itemToRemove);
    setEdu(updateList)
    console.log(updateList)
  }

  function addNewEntry() {
    const updatedList = [...education, BLANK_DATA]
    setEdu(updatedList)
    console.log(updatedList)
  }

  const [showModal, setShowModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  function validateFields() {
    for (const exp of education) {
      for (const key in BLANK_DATA) {
        if (exp[key] == "") {
          handleShowModal(`The field ${key} is required.`);
          return false;
        }
      }
    }
    return true;
  }

  const handleSubmit = (event) =>{
    
    if(!validateFields()){
      return;
    }
    
    try {
    const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
    education.forEach(edu => {
      if('id' in edu){
        axiosInterceptor.patch(`/update_edu/`+edu.id+'/', edu, {
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
              }
          });
          console.log(edu)
      } else {
        const user_id = localStorage.getItem('user_id')
        axiosInterceptor.post(`/create_edu/`+user_id+'/', edu, {
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
              }
          }); 
      }
    });
    window.location.reload();
    } catch(error) { console.log('Error:', error)}
  }

  // const blackButton = { color: 'black', textAlign: 'right' };
  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {education.map((item, index) => (
        <div key={index}>
          {/* Display each education entry */}
          <Form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <FormInput
                name="school"
                label="School"
                type="text"
                value={item.school}
                // Use the handleChange function with index parameter
                handler={(e) => handleChange(e, index)}
                required
              />
              {/* <FormInput
                name="degree"
                label="Degree"
                type="text"
                value={item.degree}
                handler={(e) => handleChange(e, index)}
                required
              /> */}
          
              <Form.Label style={{marginBottom: '0%'}}>Degree:</Form.Label>
              <Form.Group controlId="formDropdown">
                <Form.Control 
                as="select"
                name="degree"
                value={item.degree}
                onChange={(e) => handleChange(e, index)}
                >
                    {options.map((degree, index) => 
                    <option value = {degree}> {degree} </option>)}
                </Form.Control>
              </Form.Group>
             
              

              <FormInput
                name="aos"
                label="Area of Study"
                type="text"
                value={item.aos}
                handler={(e) => handleChange(e, index)}
                required
              />
              <FormInput
                name="minor"
                label="Minor"
                type="text"
                value={item.minor}
                handler={(e) => handleChange(e, index)}
              />
              <FormInput
                name="gpa"
                label="GPA"
                type="text"
                value={item.gpa}
                handler={(e) => handleChange(e, index)}
              />

              <DateInput
                sd={item.start_date}
                ed={item.end_date}
                // Use the handleChange function with index parameter
                handler={(e) => handleChange(e, index)}
              />

              <Container style={{ textAlign: "center" }}>
                <p className="d-inline-block float-right p-0">
                  <Button onClick={() => handleRemove(item)} style={{ padding: '16px', fontSize: '8px' }}> Remove </Button>
                </p>
              </Container>
              <h2>    </h2>
            </Stack>
          </Form>
        </div>
      ))}
      <Container className="d-flex justify-content-center align-items-center">
        {/* <Col> */}
          <Row className="text-center">
            <Button onClick = {addNewEntry} style={{ marginBottom: '25px' }}>Add New</Button>
            <Button onClick={handleSubmit}>Done</Button>
          </Row>
        {/* </Col> */}
      </Container>
    </div>
  );
}
