import React, { useState, useEffect } from "react";
import ScreenSpinner from "../ScreenSpinner.jsx";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import { Button } from "react-bootstrap";
import SelectedJobDetails from "./SelectedJobDetails.jsx";

const JobFinder = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedJob, setSelectedJob] = useState(null);
    const [shadowSearchParams, setShadowSearchParams] = useState({
        keyword: "",
        location: "",
        datePosted: "",
        experience: "",
        company: "",
        salary: "",
        remote: "",
    });
    const [searchParams, setSearchParams] = useState({
        keyword: "",
        location: "",
        datePosted: "",
        experience: "",
        company: "",
        salary: "",
        remote: "",
    });

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await axiosInterceptor.get(
                    `/get_unrated_active_jobs_for_user/${localStorage.getItem(
                        "user_id"
                    )}/`
                );
                setJobs(response.data);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching jobs:", error);
                setLoading(false);
            }
        };

        fetchJobs();
    }, []);

    const handleSearch = (e) => {
        e.preventDefault();
        // Implement search functionality here
        setSearchParams({ ...shadowSearchParams });
        console.log(searchParams);
    };

    const handleInputChange = (e) => {
        setShadowSearchParams({
            ...shadowSearchParams,
            [e.target.name]: e.target.value,
        });
    };

    const handleJobSelect = (job) => {
        setSelectedJob(job);
    };

    return loading ? (
        <ScreenSpinner />
    ) : (
        <div className="flex flex-col gap-y-6 h-[88vh]">
            <h1 className="text-3xl font-medium text-left">Job Finder</h1>
            <div className="flex flex-none flex-col gap-y-2">
                <div className="flex space-x-2 mb-2 w-full justify-between">
                    <div className="flex flex-row space-x-2 w-full">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Job title, keyword, or company"
                                className="w-[20.5rem] pl-12 pr-8 py-2 rounded"
                                name="keyword"
                                onChange={handleInputChange}
                            />
                            <i className="bi bi-search absolute top-1/2 transform -translate-y-1/2 left-4 text-black"></i>
                        </div>
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="City, state, or zip"
                                className="w-60  pl-12 pr-8 py-2 rounded"
                                name="location"
                                onChange={handleInputChange}
                            />
                            <i className="bi bi-geo absolute top-1/2 transform -translate-y-1/2 left-4 text-black"></i>
                        </div>
                        <Button
                            variant="outline-primary border-2 rounded-full"
                            onClick={handleSearch}
                        >
                            Search
                        </Button>
                    </div>
                    {/* <i
                        class="bi bi-bookmark float-end self-center text-2xl ml-auto mr-0 place-self-end text-[#1B4E99]"
                        style={{
                            WebkitTextStroke: "1.5px",
                        }}
                    ></i> */}
                </div>

                <div className="flex space-x-2">
                    <select
                        name="datePosted"
                        onChange={handleInputChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="">Date Posted</option>
                        {/* Add date options */}
                    </select>
                    <select
                        name="experience"
                        onChange={handleInputChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="">Experience</option>
                        {/* Add experience options */}
                    </select>
                    <select
                        name="company"
                        onChange={handleInputChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="">Company</option>
                        {/* Add company options */}
                    </select>
                    <select
                        name="salary"
                        onChange={handleInputChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="">Salary</option>
                        {/* Add salary options */}
                    </select>
                    <select
                        name="remote"
                        onChange={handleInputChange}
                        className="border-2 font-medium !pl-4 border-black py-1.5 rounded-full bg-transparent padded-select-arrow"
                    >
                        <option value="">Remote</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>
                </div>
            </div>

            <div className="flex flex-grow overflow-hidden border-t-2 border-[#B4B4B4]">
                <div className="w-1/3 pr-4 overflow-y-auto">
                    <div className="border-b-2 border-[#B4B4B4] mb-2 !-mr-4 py-3 px-4 flex flex-col gap-y-2">
                        <h3 className="font-semibold">
                            Searching for{" "}
                            {searchParams.keyword.length > 0
                                ? `"${searchParams.keyword}"`
                                : "all jobs"}{" "}
                            in{" "}
                            {searchParams.location.length > 0
                                ? `"${searchParams.location}"`
                                : "any location"}{" "}
                        </h3>
                        <div className="flex flex-row justify-between items-center">
                            <p>
                                {jobs.length}{" "}
                                {jobs.length === 1 ? "result" : "results"}
                            </p>
                            <select className="bg-transparent text-gray-600 text-sm">
                                {/* TODO: actually implement this ordering? At least for the bottom two. */}
                                <option value="mostRelevant">
                                    Most Relevant
                                </option>
                                <option value="newest">Newest</option>
                                <option value="oldest">Oldest</option>
                            </select>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-3 mt-4">
                        {jobs.map((job) => (
                            <div
                                className={`flex flex-row gap-x-4 items-start ${
                                    selectedJob?.id === job.id
                                        ? "bg-[#B1BFD8]/20"
                                        : "bg-white"
                                } p-2 rounded-lg cursor-pointer`}
                                key={job.id}
                                onClick={(e) => {
                                    handleJobSelect(job);
                                }}
                            >
                                <div className="!size-16 rounded-lg bg-[#D9D9D9]" />
                                <div className="flex flex-col gap-y-0.5 h-min w-[75%]">
                                    <div className="flex flex-row justify-between items-center">
                                        <p className="font-semibold text-black text-lg">
                                            {job.title}
                                        </p>
                                    </div>
                                    <p className="text-black font-medium">
                                        {job.company_details.company_name}
                                    </p>
                                    <p className="text-gray-700 font-medium text-sm">
                                        $
                                        {Math.round(
                                            job.compensationLowerBound / 1000
                                        )}
                                        -
                                        {Math.round(
                                            job.compensationUpperBound / 1000
                                        )}
                                        k/yr{" "}
                                        <span className="mx-1">&bull;</span>{" "}
                                        {/* NOTE: the below is a dummy */}
                                        Full Time
                                    </p>
                                    <p className="text-gray-700 font-medium text-sm mt-0.5">
                                        {job.city}{" "}
                                        <span className="mx-1">&bull;</span>{" "}
                                        {new Date(
                                            job.timestamp
                                        ).toLocaleDateString()}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="w-2/3 overflow-y-auto border-l-2 border-[#B4B4B4] h-full !px-10">
                    {selectedJob ? (
                        <div className="py-4">
                            <SelectedJobDetails job={selectedJob} />
                        </div>
                    ) : (
                        <div className="flex items-center text-center w-full h-[60vh] justify-center m-auto">
                            <p className="text-lg text-black">
                                Select a job to view details
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default JobFinder;
