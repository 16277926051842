import React from "react";
import { Button, Container, FloatingLabel, Form, Stack } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";

const EmailEntryForm = ({ onSubmit }) => {
    const [email, setEmail] = React.useState("");
    const [validated, setValidated] = React.useState(false);

    async function handleSubmit(e) {
        e.preventDefault();
        if (e.currentTarget.checkValidity()) {
            try {
                await axiosInterceptor.post("/request_password_reset/", {
                    email: email,
                    tp: "consumer",
                });
                onSubmit(email);
            } catch (err) {
                if (
                    err.response.status === 404 &&
                    err.response.data.error ===
                        "User with that email not found."
                ) {
                    // Means that a user for that email was not found — clear the field and alert.
                    setEmail("");
                    alert("No user found with that email.");
                } else {
                    console.error(err.response);
                    alert("An unknown error occurred — please try again later.")
                }
            }
        } else if (!validated) setValidated(true);
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Stack gap={2}>
                <center>
                    <h2>Reset Password</h2>
                </center>
                <FloatingLabel label="Email">
                    <Form.Control
                        name="email"
                        type="email"
                        placeholder=" "
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </FloatingLabel>
                <Container style={{ textAlign: "center" }}>
                    <Button type="submit">Submit</Button>
                </Container>
            </Stack>
        </Form>
    );
};

const EmailSentMessage = () => {
    return <p>Please check your email for a password reset link.</p>;
};

function RecoverForm(props) {
    const [step, setStep] = React.useState(0);
    const [display, setDisplay] = React.useState(null);
    const nextStep = () => setStep(step + 1);

    React.useEffect(() => {
        switch (step) {
            case 0:
                setDisplay(<EmailEntryForm onSubmit={nextStep} />);
                break;
            case 1:
                setDisplay(<EmailSentMessage />);
                break;
            default:
                setDisplay(null);
        }
    }, [step]);

    return (
        <Container style={{ maxWidth: 500, padding: 10 }}>{display}</Container>
    );
}

export default RecoverForm;
