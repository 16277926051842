import React from "react";
import { Button, Container, FloatingLabel, Form, Stack } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import { useNavigate } from "react-router-dom";

function ResetForm(props) {
    const [password, setPassword] = React.useState({
        1: "",
        2: "",
    });
    const [validated, setValidated] = React.useState(false);
    const navigate = useNavigate();

    function handleChange(e) {
        if (e.target.name === "2") {
            if (e.target.value !== password[1]) {
                e.target.setCustomValidity("passwords do not match");
            } else {
                e.target.setCustomValidity("");
            }
        }
        setPassword({ ...password, [e.target.name]: e.target.value });
    }

    async function handleSubmit(e) {
        e.preventDefault();
        if (e.currentTarget.checkValidity()) {
            try {
                const response = await axiosInterceptor.put(
                    "/attempt_password_reset/",
                    {
                        password: password[1],
                        token: new URLSearchParams(window.location.search).get(
                            "token"
                        ),
                    }
                );
                setTimeout(() => {
                    navigate("/account/login");
                }, 3000);
                alert(response.data.message + " Redirecting...");
            } catch (err) {
                console.error(err.response);
                alert(err.response.data.error);
            }
        } else if (!validated) setValidated(true);
    }

    return (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Stack gap={2}>
                <center>
                    <h2>Reset Password</h2>
                    <p>Please ensure your password is sufficiently complex.</p>
                </center>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        columns: "2",
                        gap: "1rem",
                    }}
                >
                    <FloatingLabel
                        label="New password"
                        style={{
                            width: "25%",
                        }}
                    >
                        <Form.Control
                            name="1"
                            type="password"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_\-])[A-Za-z\d@$!%*?&_\-]{8,}$"
                            placeholder=" "
                            required
                            value={password[1]}
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                    <FloatingLabel
                        label="Confirm new password"
                        style={{
                            width: "25%",
                        }}
                    >
                        <Form.Control
                            name="2"
                            type="password"
                            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_\-])[A-Za-z\d@$!%*?&_\-]{8,}$"
                            placeholder=" "
                            required
                            value={password[2]}
                            onChange={handleChange}
                        />
                    </FloatingLabel>
                </div>
                <Container
                    style={{ textAlign: "center", marginBottom: "2rem" }}
                >
                    <Button
                        type="submit"
                        disabled={password[1] !== password[2]}
                    >
                        Reset
                    </Button>
                </Container>
            </Stack>
        </Form>
    );
}

export default ResetForm;
