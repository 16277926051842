import React from "react";
import Hero from "../../components/Hero.jsx";
import Faq from "react-faq-component";

export default function FAQ(props) {
    let data = {
        rows: [
            {
                title: <h5>What is AlgoLink?</h5>,
                content: (
                    <h6 style={{ color: "gray" }}>
                        AlgoLink is a cutting-edge platform with an integrated
                        Test Center that helps you evaluate and develop your
                        skills. We assist in matching your goals and talents
                        with the appropriate possibilities in Software
                        Engineering, Data Science, Machine Learning Research,
                        and Quantitative Finance by assessing your ability and
                        making tailored course recommendations.
                    </h6>
                ),
            },
            {
                title: <h5>How does AlgoLink work?</h5>,
                content: (
                    <h6 style={{ color: "gray" }}>
                        AlgoLink utilizes a sophisticated Test Center to assess
                        your skills. Based on your performance, along with your
                        user profile and survey responses, we provide tailored
                        course recommendations to help you improve. These
                        assessments give our Technical Recruiters a
                        comprehensive understanding of your skills and
                        expertise.
                    </h6>
                ),
            },
            {
                title: (
                    <h5>
                        What information do I need to provide when creating an
                        account?
                    </h5>
                ),
                content: (
                    <h6 style={{ color: "gray" }}>
                        When signing up, you'll provide details about your
                        educational background, professional experience, and
                        areas of interest within computer science and related
                        fields. This information helps us tailor the Test Center
                        assessments and course recommendations to your specific
                        needs.
                    </h6>
                ),
            },
            {
                title: <h5>What is the Test Center?</h5>,
                content: (
                    <h6 style={{ color: "gray" }}>
                        The Test Center is the core feature of AlgoLink. It
                        includes a variety of assessments designed to evaluate
                        your skills and abilities in different areas. Your
                        performance in these assessments helps us understand
                        your strengths and areas for improvement, and generate
                        personalized course recommendations.
                    </h6>
                ),
            },
            {
                title: <h5>How do the assessments in the Test Center work?</h5>,
                content: (
                    <h6 style={{ color: "gray" }}>
                        The assessments are designed to be comprehensive and
                        cover various topics relevant to your field. After
                        completing an assessment, you'll receive detailed
                        feedback on your performance, which will be used to
                        generate personalized course and job recommendations.
                    </h6>
                ),
            },
            {
                title: (
                    <h5>
                        How do Technical Recruiters use my information and
                        submissions?
                    </h5>
                ),
                content: (
                    <h6 style={{ color: "gray" }}>
                        Our experienced Technical Recruiters analyze your
                        performance in the Test Center, along with your user
                        profile and survey responses, to understand your
                        strengths and career aspirations. This information helps
                        them match you with relevant job positions.
                    </h6>
                ),
            },
            {
                title: <h5>What kind of job opportunities can I expect?</h5>,
                content: (
                    <h6 style={{ color: "gray" }}>
                        Based on your profile and Test Center results, you can
                        expect job opportunities in Software Engineering, Data
                        Science, Machine Learning Research, and Quantitative
                        Finance, tailored to your expertise and career goals.
                    </h6>
                ),
            },
            {
                title: <h5>Are the MIT OCW submissions mandatory?</h5>,
                content: (
                    <h6 style={{ color: "gray" }}>
                        While not mandatory, submitting your work on MIT OCW
                        lectures and problem sets can provide additional
                        insights into your skills and knowledge, further
                        enhancing the accuracy of our course recommendations.
                    </h6>
                ),
            },
            {
                title: (
                    <h5>
                        What kind of submissions are expected for MIT lectures
                        and problem sets?
                    </h5>
                ),
                content: (
                    <h6 style={{ color: "gray" }}>
                        Submissions for MIT lectures and problem sets are
                        designed to supplement your learning. These are
                        typically problem-solving exercises and projects that
                        align with the topics covered in the Test Center
                        assessments.
                    </h6>
                ),
            },
            {
                title: (
                    <h5>
                        How can I maximize my chances of getting matched with
                        the right opportunities?
                    </h5>
                ),
                content: (
                    <h6 style={{ color: "gray" }}>
                        To maximize your chances, actively participate in the
                        Test Center assessments and provide complete and
                        accurate information in your user profile and survey
                        responses. The more data we have, the better we can
                        tailor our recommendations and match you with suitable
                        opportunities.
                    </h6>
                ),
            },
            {
                title: <h5>How will AlgoLink help me improve my skills?</h5>,
                content: (
                    <h6 style={{ color: "gray" }}>
                        Based on your Test Center results, user profile, and
                        survey responses, AlgoLink will recommend courses and
                        resources within our curriculum specifically designed to
                        help you improve in areas where you need it most.
                        Regularly engaging with these resources will help you
                        enhance your skills over time.
                    </h6>
                ),
            },
            {
                title: <h5>How do I get started with AlgoLink?</h5>,
                content: (
                    <h6 style={{ color: "gray" }}>
                        Getting started is easy! Just sign up on our website,
                        fill in your educational and professional details, and
                        start exploring our Test Center and resources. Begin by
                        taking assessments to receive personalized course
                        recommendations and improve your skills.
                    </h6>
                ),
            },
        ],
    };

    if (props.limit) {
        data.rows = data.rows.slice(0, props.limit);
    }

    let styles = {
        bgColor: "light gray",
        titleTextColor: "#1B4E99",
        titleTextSize: "40px",
        rowTitleColor: "#1B4E99",
        rowTitleTextSize: "20px",
        rowContentColor: "gray",
        rowContentTextSize: "14px",
        arrowColor: "black",
        transitionDuration: "0.75s",
        timingFunc: "ease",
    };

    // Extend style with props.customStyle if exists.
    if (props.customStyle) {
        styles = { ...styles, ...props.customStyle };
    }

    console.log(styles);

    const config = {
        expandIcon: "+",
        collapseIcon: "-",
    };

    return props.showHeader ?? true ? (
        <main>
            <Hero content="Frequently Asked Questions" />

            <div
                style={{
                    marginLeft: "50px",
                    marginRight: "50px",
                    marginTop: "50px",
                    marginBottom: "50px",
                }}
            >
                <Faq data={data} styles={styles} config={config} />
            </div>
        </main>
    ) : (
        <Faq data={data} styles={styles} config={config} />
    );
}
