import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Stack from "react-bootstrap/esm/Stack";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import sendVerificationCode from "../../utils/sendEmailVerification.ts";

function UserVerifyForm(props) {
    const navigate = useNavigate();
    const [code, setCode] = React.useState(["", "", "", "", "", ""]);
    const inputRefs = useRef([]);
    const [email, setEmail] = React.useState("...");
    const [timeLastSent, setTimeLastSent] = React.useState(
        new Date().getTime()
    );
    const [currentTime, setCurrentTime] = React.useState(new Date().getTime());

    // Check if the user's email is already verified.
    useEffect(() => {
        const isEmailVerified = localStorage.getItem("is_email_verified");
        if (isEmailVerified === "true") {
            navigate("/");
            console.log("Already verified, redirecting to home");
        }
    }, [navigate]);

    const updateTimer = () => {
        setCurrentTime(currentTime + 1000);
    };

    // Update the time countdown.
    setTimeout(() => {
        updateTimer();
    }, 1000);

    // Read in email from the API endpoint.
    const user_id = localStorage.getItem("user_id");
    React.useEffect(() => {
        try {
            axiosInterceptor
                .get(`getuser/` + user_id + "/")
                .then((response) => {
                    setEmail(response.data["u_data"]["email"]);
                });
        } catch (error) {
            console.error("error:", error);
        }
    }, [user_id]);

    function handleCodeChange(index, value) {
        if (/^[0-9]?$/.test(value)) {
            const newCode = [...code];
            newCode[index] = value;
            setCode(newCode);

            if (value && index < 5) {
                inputRefs.current[index + 1].focus();
            } else if (!value && index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    }

    function handleKeyDown(index, e) {
        if (e.key === "Backspace" && !code[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        const fullCode = code.join("");
        confirmSignUp(fullCode).then((res) => {
            if (!!res) navigate("/");
        });
    }

    async function confirmSignUp(fullCode) {
        try {
            const response = await axiosInterceptor.get(
                "/check_verification_code/?code=" + fullCode + "&email=" + email
            );
            // An error is thrown so the below check is redundant.
            if (response.status >= 200 && response.status < 300) {
                localStorage.setItem("is_email_verified", true);
                alert("Account verified successfully!");
                setTimeout(() => {
                    navigate("/account/pending-algolink-verification");
                }, 250);
                return Promise.resolve(true);
            }
        } catch (error) {
            console.log(error);
            return Promise.resolve(false);
        }
    }

    const triggerResendCodeLogic = async () => {
        await sendVerificationCode(email);
        setTimeLastSent(new Date().getTime());
        alert("Email resent! Please check your inbox.");
    };

    return (
        <div className="max-w-[370px] lg:max-w-lg mx-auto w-full border rounded-lg p-4 border-opacity-100 bg-[#F6F6F6] bg-opacity-50 text-center flex flex-col gap-2.5">
            <h2 className="text-2xl font-semibold">Account Verification</h2>
            <p className="font-medium text-black text-sm">
                A 6-digit verification code has been sent to {email}.
            </p>
            <Form
                onSubmit={handleSubmit}
                className="flex flex-col gap-2 items-center"
            >
                <div className="flex gap-2 my-3.5">
                    {[0, 1, 2, 3, 4, 5].map((index) => (
                        <Form.Control
                            key={index}
                            ref={(el) => (inputRefs.current[index] = el)}
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]"
                            maxLength={1}
                            value={code[index]}
                            onChange={(e) =>
                                handleCodeChange(index, e.target.value)
                            }
                            onKeyDown={(e) => handleKeyDown(index, e)}
                            className="w-10 h-10 text-center border-[1.5px] border-[#555555] focus:!border-[#1B4E99] focus:!shadow-[0_0_5px_rgba(27,78,153,0.5)] transition-all duration-200"
                        />
                    ))}
                </div>
                <Button
                    type="submit"
                    className="bg-special-blue border-special-blue hover:border-special-blue hover:!bg-special-blue active:border-special-blue active:bg-special-blue w-[240px]"
                >
                    Verify
                </Button>
            </Form>
            <p className="text-sm font-medium text-black">
                Didn't receive an email?{" "}
                <button
                    onClick={triggerResendCodeLogic}
                    className={
                        "text-special-blue font-medium hover:underline " +
                        (email === "..." ||
                        new Date().getTime() - 60_000 < timeLastSent
                            ? "!cursor-not-allowed"
                            : "cursor-pointer")
                    }
                    disabled={
                        email === "..." ||
                        new Date().getTime() - 60_000 < timeLastSent
                    }
                >
                    Resend
                </button>{" "}
                {Math.round(60 - (currentTime - timeLastSent) / 1000) > 0
                    ? "(" +
                      Math.round(60 - (currentTime - timeLastSent) / 1000) +
                      ")"
                    : ""}
            </p>
        </div>
    );
}

export default UserVerifyForm;
