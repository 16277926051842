// import React, { useState } from "react";
import React from "react";
import { dateToString } from "../../../utils/formatter";

import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Form, Modal } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import FormInput, { DateInput } from "../../FormInput";
import axiosInterceptor from "../../../utils/axiosInterceptor.ts";

import { Row } from "react-bootstrap";

export default function Experience({ expList }) {
  function ExpItem({ info }) {
    const {
      company,
      employ_type,
      position,
      start_date,
      end_date,
      description,
    } = info;
  
    const startDateAsMonthYear = new Date(start_date).toLocaleString('default', { month: 'long', year: 'numeric' });
    var endDateAsMonthYear = "Present"
    if (end_date !== null || end_date === "") {
      endDateAsMonthYear = new Date(end_date).toLocaleString('default', { month: 'long', year: 'numeric' });
      }
    const date = startDateAsMonthYear +" – "+ endDateAsMonthYear

    if (typeof company === "undefined") {
      console.log("Experience no longer exists");
      return;
    } else {
      return (
        // <div className="mb-2">
        //   <div className="position-relative">
        //     <h5 className="d-inline-block" style={{ paddingRight: ".25rem" }}>
        //       {company}
        //     </h5>
        //     <p className="d-inline-block p-0">{employ_type}</p>
        //     <p className="d-inline-block float-right p-0 ">
        //       {dateToString(start_date, end_date)}
        //     </p>
        //   </div>
        //   <div>
        //     <h6>{position}</h6>
        //   </div>
        //   <div>
        //     <p>{description}</p>
        //   </div>
        // </div>
        <div className="flex flex-row items-center justify-between mb-3">
          <div className="flex flex-row gap-x-4 items-start">
            <div className="h-16 rounded-lg w-16 bg-[#D9D9D9]" />
            <div className="flex flex-col gap-y-0.5 h-min">
              <p className="font-semibold text-black text-lg">{position}</p>
              <p className="text-black font-medium">{company} <span className="mx-1">&bull;</span> {employ_type}</p>
              {/* <p className="text-gray-700">Ithaca, NY</p> */}
              <p className="text-gray-700">{date}</p>
              <p className="text-gray-700 font-normal mt-2">{description}</p>
            </div>
          </div>
          {/* <i className="bi text-lg cursor-pointer bi-pencil-square"></i> */}
        </div>
      );
    }
  }
  const sortedExpList = expList.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));

  return (
    <div className="-mb-3">
      {sortedExpList.map((item, i) => {
        return <ExpItem key={i} info={item} />;
      })}
    </div>
  );
}

//Add Validation and Submit
export function CreateExp({ expList, setExperiences }) {
  const BLANK_DATA = {
    relevance: -1,
    position: "",
    company: "",
    employ_type: "Internship",
    start_date: "",
    end_date: "",
    description: "",
  };

  var start_info = BLANK_DATA

  if (expList != null && JSON.stringify(expList) !== "{}"){start_info = expList}
  
  const [experiences, setExp] = React.useState(start_info);



  function updateExperienceItem(index, field, value) {
    // Create a copy of the eduList
    const updatedList = [...experiences];

    // Update the specified item with the new values
    updatedList[index] = {
      ...updatedList[index],
      [field]: value,
    };
    setExp(updatedList);
    // console.log(experiences);
  }

  const handleChange = function (e, index) {
    updateExperienceItem(index, e.target.name, e.target.value);
  };

  function handleRemove(itemToRemove) {
    console.log('itemtoremove:', itemToRemove)
    if('id' in itemToRemove){
      console.log('has id')
      try{
        const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
        axiosInterceptor.delete(`/delete_exp_entry/`+itemToRemove.id+'/', itemToRemove, {
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
              }
          });
      }
      catch(error){
        console.log('Error: ', error)
      }
    }
    const updateList = experiences.filter((item) => item !== itemToRemove);
    setExp(updateList);
    // console.log(experiences);
  }

  function addNewEntry() {
    const updateList = [...experiences, BLANK_DATA];
    setExp(updateList);
    // console.log(experiences);
  }

  const [showModal, setShowModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState('');

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (message) => {
    setModalMessage(message);
    setShowModal(true);
  };

  function validateFields() {
    for (const exp of experiences) {
      for (const key in BLANK_DATA) {
        if (exp[key] == "" && key != "end_date") {
          handleShowModal(`The field ${key} is required.`);
          return false;
        }
      }
    }
    return true;
  }

  const handleSubmit = (event) =>{
    const user_id = localStorage.getItem('user_id')
    // console.log(experiences)
    
    if(!validateFields()){
      return;
    }
    
    try {
    const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
    experiences.forEach(exp => {
      if('id' in exp){
        axiosInterceptor.patch(`/update_exp/`+exp.id+'/', exp, {
          headers: {
              'Content-Type': 'application/json',
              'X-CSRFToken': csrfToken,
              }
          });
      }
      else{
          axiosInterceptor.post(`/create_exp/`+user_id+'/', exp, {
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': csrfToken,
                }
            });
      }
    });

    } catch(error) { console.log('Error:', error)}
   window.location.reload();
  }

  return (
    <div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {experiences.map((item, index) => (
        <div key={index}>
          <Form onSubmit={handleSubmit}>
            <Stack gap={2}>
              <FormInput
                name={"position"}
                label={"Position"}
                type={"text"}
                value={item.position}
                handler={(e) => handleChange(e, index)}
                required
              />

              <Form.Group controlId="formDropdown">
                <Form.Control
                  as="select"
                  name="employ_type"
                  value={item.employ_type}
                  onChange={(e) => handleChange(e, index)}
                >
                  <option value="Internship">Internship</option>
                  <option value="New Grad">New Graduate</option>
                  <option value="Full Time">Full Time</option>
                  <option value="Part Time">Part Time</option>
                </Form.Control>
              </Form.Group>

              <FormInput
                name={"company"}
                label={"Company Name"}
                type={"text"}
                value={item.company}
                handler={(e) => handleChange(e, index)}
                required
              />

              <DateInput
                pos
                sd={item.start_date}
                ed={item.end_date}
                handler={(e) => handleChange(e, index)}
              />

              <Form.Label className="m-0">Description</Form.Label>
              <Form.Control
                name="description"
                as="textarea"
                onChange={(e) => handleChange(e, index)}
                value={item.description}
              />
              <Container style={{ textAlign: "center" }}>
                <p className="d-inline-block float-right p-0">
                  <Button
                    onClick={() => handleRemove(item)}
                    style={{
                      padding: "16px",
                      fontSize: "8px",
                    }}
                  >
                    {" "}
                    Remove{" "}
                  </Button>
                </p>
              </Container>
              <h2> </h2>
            </Stack>
          </Form>
        </div>
      ))}
      <Container className="d-flex justify-content-center align-items-center">
        <Row>
          {/* <Col className="text-center"> */}
            <Button onClick={addNewEntry} style={{ marginBottom: '25px'}}>
              Add New
            </Button>
            <Button onClick={handleSubmit}>Done</Button>
          {/* </Col> */}
        </Row>
      </Container>
    </div>
  );
}
