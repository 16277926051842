import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-solid-svg-icons";

const Timer = ({ onTimeUp }) => {
  const [seconds, setSeconds] = useState(60 * 60);

  useEffect(() => {
    if (seconds > 0) {
      const interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);

      return () => clearInterval(interval);
    } else if (seconds === 0 && onTimeUp) {
      onTimeUp();
    }
  }, [seconds, onTimeUp]);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const secs = totalSeconds % 60;
    return `${minutes}:${secs < 10 ? `0${secs}` : secs}`;
  };

  return (
    <div className="timer">
      <h3>
        <FontAwesomeIcon
          className="clock"
          icon={faClock}
          style={{ color: "#ffffff" }}
        />
        {formatTime(seconds)}
      </h3>
    </div>
  );
};

export default Timer;
