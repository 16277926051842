import { Navigate, useLocation } from "react-router-dom";

export function VerificationRequiredRoute({
    component: Component,
    emailSuffices = false,
    adminRequired = false,
    ...rest
}) {
    const isEmailVerified =
        localStorage.getItem("is_email_verified") === "true";
    const isVerifiedByAlgolink =
        localStorage.getItem("is_verified_by_algolink") === "true";
    const isAuthenticated = localStorage.getItem("access_token") || false;
    const isAlgoLinkAdmin = localStorage.getItem("is_algolink_admin") || false;
    const location = useLocation();

    if (
        isAuthenticated &&
        isEmailVerified &&
        !isVerifiedByAlgolink &&
        !emailSuffices // don't want an infinite loop
    ) {
        return (
            <Navigate
                to={{
                    pathname: "/account/pending-algolink-verification",
                    state: { from: location },
                }}
            />
        );
    }

    // However, if we are verified and !emailSuffices and location is /account/pending-algolink-verification, then we should redirect to the home page.
    if (
        isAuthenticated &&
        isEmailVerified &&
        isVerifiedByAlgolink &&
        location.pathname === "/account/pending-algolink-verification"
    ) {
        return <Navigate to="/" />;
    }

    if (
        isAuthenticated &&
        !isEmailVerified &&
        location.pathname !== "/profile" &&
        location.pathname !== "/account/verify" &&
        location.pathname !== "/account/signup" &&
        location.pathname !== "/account/login"
    ) {
        return (
            <Navigate
                to={{ pathname: "/account/verify", state: { from: location } }}
            />
        );
    } else if (
        !isAuthenticated &&
        location.pathname !== "/account/verify" &&
        location.pathname !== "/account/signup" &&
        location.pathname !== "/account/login"
        // &&
        // localStorage.getItem("current_step")
    ) {
        return (
            <Navigate
                to={{ pathname: "/account/signup", state: { from: location } }}
            />
        );
    } else {
        // Also have the admin catch.
        if (adminRequired && !isAlgoLinkAdmin) {
            return (
                <Navigate to={{ pathname: "/", state: { from: location } }} />
            );
        }
        return <Component {...rest} />;
    }
}
