import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Webcam from "react-webcam";
import { Button, Alert } from "react-bootstrap";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";

function TechnicalHome() {
  const navigate = useNavigate();
  const [selectedSubjects, setSelectedSubjects] = useState([]);
  const [selectedDifficulty, setSelectedDifficulty] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  const handleSubjectChange = (event) => {
    const selectedValue = event.target.value
    setSelectedSubjects(() => {
      if (selectedSubjects.includes(selectedValue)) {
        return selectedSubjects.filter(subject => subject !== selectedValue);
      }
      else {
        return [...selectedSubjects, selectedValue];
      }
    });
  };

  const handleDifficultyChange = (event) => {
    setSelectedDifficulty(event.target.value);
  };

  const handleStartInterview = async () => {
    if (!selectedSubjects || !selectedDifficulty) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
      const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1];
      const request = {num_samples: 5, subjects: selectedSubjects}
      console.log(request)
      try {
        const response = await axiosInterceptor.post('/sample_questions/', request, {
          headers:{
            'Content-Type': 'application/json',
            'X-CSRFToken': csrfToken,
          }
        });
        // console.log(response)
        navigate("/test-center/technical", {state: response.data});
      } catch (error) {
        console.log(error);
      } 
    }
  };

  return (
    <div className="parent">
      <div className="header">
        <h3>Technical</h3>
      </div>
      <div className="question-card">
        <div className="tech-home-left">
          <p>
            Choose a difficulty and choose subjects before you start the
            interview. This is to tailor your questions to your interests and
            skill level.
          </p>
          <div>
            <label htmlFor="course-options">Choose a course:</label>
            <select
              id="course-options"
              value={selectedSubjects}
              onChange={handleSubjectChange}
              multiple={true}
            >
              <option value="">--Please choose a subject--</option>
              <option value="option1">Math Competition</option>
              <option value="option2">Logic/Reasoning</option>
              <option value="option3">Fundamental Finance</option>
              <option value="option4">Specialized Finance</option>
              <option value="option5">Coding Syntax Proficiency</option>
              <option value="option6">Algorithms/Data Structures</option>
              <option value="option7">Backend</option>
              <option value="option8">Game Theory</option>
              <option value="option9">Market Making</option>
              <option value="option10">Statistics</option>
              <option value="option11">Probability</option>
              <option value="option12">Fundamental Math</option>
              <option value="option13">Basic Machine Learning</option>
              <option value="option14">Complex Machine Learning</option>
            </select>
          </div>
          <div>
            <label htmlFor="difficulty-options">Choose a difficulty:</label>
            <select
              id="difficulty-options"
              value={selectedDifficulty}
              onChange={handleDifficultyChange}
            >
              <option value="">--Please choose a difficulty--</option>
              <option value="easy">Easy</option>
              <option value="medium">Medium</option>
              <option value="hard">Hard</option>
            </select>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
          className="webcam-container"
        >
          <Webcam
            style={{
              borderRadius: "20px",
              marginBottom: "20px",
              width: "auto",
              marginLeft: "7%",
            }}
          />
          <Button onClick={handleStartInterview}>Start Interview</Button>
          {showAlert && (
            <Alert variant="danger" style={{ marginTop: "20px" }}>
              Please choose a course and difficulty before starting the
              interview.
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
}

export default TechnicalHome;
