import React, { useState, useEffect } from "react";
import CodeMirror from "@uiw/react-codemirror";
import { javascript } from "@codemirror/lang-javascript";
import { python } from "@codemirror/lang-python";
import { cpp } from "@codemirror/lang-cpp";
import { tokyoNight } from "@uiw/codemirror-theme-tokyo-night";
import axiosInterceptor from "../../utils/axiosInterceptor.ts";

const languageOptions = {
  javascript: javascript(),
  python: python(),
  cpp: cpp(),
};

const styles = {
  dropDown: {
    backgroundColor: "#1a1b26",
    color: "#ffffff",
    borderStyle: "none",
    width: "110px",
  },
  codingBox: {
    margin: "0 auto",
    width: "100%",
    alignItems: "center",
  },
  button: {
    marginLeft: "10px",
    backgroundColor: "#000000",
    padding: "5px 10px",
    borderRadius: "10px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  consoleButton: (isActive) => ({
    backgroundColor: isActive ? "#1a1b26" : "#6782b4",
  }),
  testCaseButton: (isActive) => ({
    backgroundColor: isActive ? "#373737" : "#000000",
  }),
};

const initialCode = {
  javascript: "function userFunction(a, b) {\n return;\n}",
  python: "def user_function(a, b):\n return",
  cpp: "int userFunction(int a, int b) {\n return;\n}",
};

const CodingBox = () => {
  const [code, setCode] = useState(initialCode.python);
  const [language, setLanguage] = useState("python");
  const [testCases, setTestCases] = useState([]);
  const [testResults, setTestResults] = useState([]);
  const [currentTestCase, setCurrentTestCase] = useState(null);
  const [consoleState, setConsoleState] = useState(true);
  const [codeRun, setCodeRun] = useState(false);

  useEffect(() => {
    const fetchTestCases = async () => {
      const data = await testCaseData();
      setTestCases(data.map(({ input }) => createTestCase(input)));
      setCurrentTestCase(0);
    };
    fetchTestCases();
  }, []);

  const handleLanguageChange = (e) => {
    const newLanguage = e.target.value;
    setLanguage(newLanguage);
    setCode(initialCode[newLanguage]);
  };

  const createTestCase = (input) => (
    <div className="test-case-component input-container">
      <h6 style={{ color: "#ffffff", marginTop: "10px" }}>Input</h6>
      {input.map((element, idx) => (
        <div className="value-container" key={idx}>
          {element}
        </div>
      ))}
    </div>
  );

  const createTestResult = (result, input, output, expected_output) => {
    const isPass = result === "Pass";
    return (
      <div className="test-case-container">
        <h3 style={{ color: isPass ? "green" : "red" }}>
          {isPass ? "Correct" : "Incorrect"}
        </h3>
        {createTestCase(input)}
        <div className="test-case-component output-container">
          <h6>Output</h6>
          <div className="value-container">{output}</div>
        </div>
        <div className="test-case-component expected-output-container">
          <h6>Expected Output</h6>
          <div className="value-container">{expected_output}</div>
        </div>
      </div>
    );
  };

  const createTestCaseButtons = () =>
    testCases.map((_, index) => (
      <button
        key={index}
        onClick={() => setCurrentTestCase(index)}
        style={styles.testCaseButton(index === currentTestCase)}
      >
        Case {index + 1}
      </button>
    ));

  const testCaseData = async () => {
    try {
      const { data } = await axiosInterceptor.post("/run_code/", {
        code,
        language,
      });
      return data.results;
    } catch (error) {
      console.error("Error running code:", error);
    }
  };

  // const renderConsole = () => {
  //   return <div className="test-case-buttons">{createTestCaseButtons()}</div>;
  // };

  const handleSubmit = async () => {
    const data = await testCaseData();
    const newTestCases = data.map(({ input }) => createTestCase(input));
    const newTestResults = data.map(
      ({ result, input, output, expected_output }) =>
        createTestResult(result, input, output, expected_output)
    );
    setTestCases(newTestCases);
    setTestResults(newTestResults);
    setCurrentTestCase(0);
    setConsoleState(false);
    setCodeRun(true);
  };

  return (
    <div>
      <div
        className="coding-box-top"
        style={{ display: "flex", flexDirection: "column", gap: "20px" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <select
            style={styles.dropDown}
            value={language}
            onChange={handleLanguageChange}
          >
            {Object.keys(languageOptions).map((lang) => (
              <option key={lang} value={lang}>
                {lang.charAt(0).toUpperCase() + lang.slice(1)}
              </option>
            ))}
          </select>
          <button style={styles.button} onClick={handleSubmit}>
            Run Code
          </button>
        </div>
        <div style={styles.codingBox}>
          <CodeMirror
            value={code}
            height="378px"
            theme={tokyoNight}
            extensions={[languageOptions[language]]}
            onChange={(value) => setCode(value)}
          />
        </div>
      </div>
      <div className="console">
        <div className="console-header">
          <button
            onClick={() => setConsoleState(true)}
            style={styles.consoleButton(consoleState)}
          >
            Test Case
          </button>
          <button
            onClick={() => setConsoleState(false)}
            style={styles.consoleButton(!consoleState)}
          >
            Test Result
          </button>
        </div>
        <div className="inner-console">
          <div className="test-case-buttons">
            {(consoleState || (!consoleState && codeRun)) &&
              createTestCaseButtons()}
          </div>
          {consoleState
            ? testCases[currentTestCase]
            : testResults[currentTestCase]}
        </div>
      </div>
    </div>
  );
};

export default CodingBox;
