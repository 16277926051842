import React, { useState, useRef, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../routes/test_center/ProgressBar";
import CameraRecorder from "../routes/test_center/CameraRecorder";
import Webcam from "react-webcam";

export default function InterviewTemplate({ type, questions }) {
  const navigate = useNavigate();
  const cameraRecorderRef = useRef(null);
  const [isQuestionsBegun, setIsQuestionsBegun] = useState(false);
  const [questionsAsked, setQuestionsAsked] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const numQuestionsToAsk = 3;

  console.log(questions);

  useEffect(() => {
    const shuffledQuestions = questions.sort(() => 0.5 - Math.random());
    setQuestionsAsked(shuffledQuestions.slice(0, numQuestionsToAsk));
  }, [questions]);

  const startInterview = () => {
    setIsQuestionsBegun(true);
  };

  const exitPage = (url) => {
    if (cameraRecorderRef.current) {
      cameraRecorderRef.current.stopAndDownload();
    } else {
      console.log("cameraRecorderRef is null");
    }
    setTimeout(() => navigate(url), 1000);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex === questionsAsked.length - 1) {
      setTimeout(
        () => setCurrentQuestionIndex((prevIndex) => prevIndex + 1),
        1000
      );
      exitPage();
    } else {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  return (
    <div className="parent">
      <div className="header">
        <h3>{type}</h3>
      </div>
      {isQuestionsBegun ? (
        currentQuestionIndex < questionsAsked.length ? (
          <div className="question-card">
            <div className="behavioral-left">
              <ProgressBar
                completed={(
                  ((currentQuestionIndex + 1) / numQuestionsToAsk) *
                  100
                ).toFixed(1)}
              />
              <h5>Question {currentQuestionIndex + 1}</h5>
              <p>{questionsAsked[currentQuestionIndex].question}</p>
            </div>
            <div className="webcam-container">
              <CameraRecorder
                isBackground={true}
                ref={cameraRecorderRef}
                ids={questionsAsked.map((question) => question.id)}
                type="background"
              />
              <div className="submit-buttons-behavioral">
                <button
                  className="exit-btn"
                  onClick={() => exitPage("/test-center/home")}
                >
                  Exit Test
                </button>
                <button className="next-btn" onClick={handleNextQuestion}>
                  {currentQuestionIndex === questionsAsked.length - 1
                    ? "Submit Test"
                    : "Next"}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="question-card">
            <h5>You have completed all questions!</h5>
            <button
              className="exit-btn"
              onClick={() => exitPage("/test-center/home")}
            >
              Exit Test
            </button>
          </div>
        )
      ) : (
        <div className="question-card">
          <div className="behavioral-left">
          {type === "Behavioral" ? (
            <>
            <h5>Instructions</h5>
            <p>
              1. Select the types of jobs you are interested in applying for. <br />
              2. You will be given a set of behavioral interview questions. Please
              answer each question within the time limit using the STAR response 
              format.  <br />
              3. Submit each answer when you are done with your response. 
              (Note that all answers will automatically submit once the time is up) <br />
            </p>
            </>
              ) : (
            <>
            <h5>Instructions</h5>
            <p>
              1. Select the types of jobs you are interested in applying for. <br />
              2. You will be given a set of interview questions about your professional
              and academic background. Please answer each question within the time limit. <br />
              3. Submit each answer when you are done with your response. 
              (Note that all answers will automatically submit once the time is up) <br />
            </p>
            </>
                )}
            {/* <h5>Skills Tested</h5>
            <p>
              1. <br />
              2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. <br />
              3. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. <br />
            </p> */}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            className="webcam-container"
          >
            <Webcam
              style={{
                borderRadius: "20px",
                marginBottom: "20px",
                width: "auto",
                marginLeft: "7%",
              }}
            />
            <Button onClick={startInterview}>Start Interview</Button>
          </div>
        </div>
      )}
    </div>
  );
}
