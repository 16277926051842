import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "./ProgressBar";
import CodingBox from "./CodingBox";
import CameraRecorder from "./CameraRecorder";
import Timer from "./Timer";
// import { technicalQuestions } from "./QuestionBank";
import { useLocation } from "react-router-dom";

function Technical() {
  const location = useLocation();

  //The data in [interviewData] are a collection of questions randomly drawn from our actual bank in s3
  const interviewData = location.state
  var questions = []
  var leetcodes = []
  if (interviewData) {
    questions = interviewData["Questions"]
    leetcodes = interviewData["Leetcodes"]
  }
  console.log(interviewData)
  const navigate = useNavigate();
  const cameraRecorderRef = useRef(null);
  const [questionsAsked, setQuestionsAsked] = useState(questions);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  // useEffect(() => {
  //   const shuffledQuestions = technicalQuestions.sort(
  //     () => 0.5 - Math.random()
  //   );
  //   setQuestionsAsked(shuffledQuestions.slice(0, 3));
  // }, []);

  const exitPage = (url) => {
    if (cameraRecorderRef.current) {
      cameraRecorderRef.current.stopAndDownload();
    } else {
      console.log("cameraRecorderRef is null");
    }
    if (currentQuestionIndex == questionsAsked.length) {
      navigate(url);
    } else {
      setTimeout(() => navigate(url), 1000);
    }
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex == questionsAsked.length - 1) {
      setTimeout(
        () => setCurrentQuestionIndex((prevIndex) => prevIndex + 1),
        1000
      );
      exitPage();
    } else {
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handleTimeUp = () => {
    exitPage();
    setCurrentQuestionIndex(questionsAsked.length - 1);
  };

  return (
    <div className="parent">
      <div className="header">
        <h3>Technical</h3>
        </div>
        {currentQuestionIndex < questionsAsked.length ? (
          leetcodes[currentQuestionIndex] === 1 ? (
            <div className="question-card">
              <div className="tech-left">
                <ProgressBar
                  completed={(((currentQuestionIndex + 1) / questionsAsked.length) * 100).toFixed(1)}
                />
                <div className="tech-question-box">
                  <h5>Question {currentQuestionIndex + 1}</h5>
                  <p>{questionsAsked[currentQuestionIndex].question}</p>
                  <div className="examples-box">
                    <div>
                      <h6>Example 1</h6>
                      <p>{questionsAsked[currentQuestionIndex].example1}</p>
                    </div>
                    <div>
                      <h6>Example 2</h6>
                      <p>{questionsAsked[currentQuestionIndex].example2}</p>
                    </div>
                  </div>
                </div>
                <CameraRecorder
                  isTechnical={true}
                  ref={cameraRecorderRef}
                  ids={Array.from({ length: questionsAsked.length }, (_, index) => index + 1)}
                  type="technical"
                />
              </div>
              <div className="tech-right">
                <Timer onTimeUp={handleTimeUp} />
                <CodingBox />
              </div>
            </div>
          ) : (
            <div className="parent">
            <div className="header">
              <h3>Technical</h3>
              </div>
                <div className="question-card">
                  <div className="behavioral-left">
                    <ProgressBar
                      completed={(
                        ((currentQuestionIndex + 1) / questionsAsked.length) *
                        100
                      ).toFixed(1)}
                    />
                    <h5>Question {currentQuestionIndex + 1}</h5>
                    <p>{questionsAsked[currentQuestionIndex]}</p>
                  </div>
                  <div className="webcam-container">
                    <CameraRecorder
                      isTechnical={true}
                      ref={cameraRecorderRef}
                      ids={Array.from({ length: questionsAsked.length }, (_, index) => index + 1)}
                      type="technical"
                    />
                    <div className="submit-buttons-behavioral">
                      <button
                        className="exit-btn"
                        onClick={() => exitPage("/test-center/home")}
                      >
                        Exit Test
                      </button>
                      <button className="next-btn" onClick={handleNextQuestion}>
                        {currentQuestionIndex === questionsAsked.length - 1
                          ? "Submit Test"
                          : "Next"}
                      </button>
                    </div>
                  </div>
                </div>
          </div>
          )
          ) : (
          <div className="question-card">
            <h5>You have completed all questions!</h5>
          </div>
        )};
        <div className="submit-buttons">
          <button className="b1" onClick={() => exitPage("/test-center/home")}>
            Exit Test
          </button>
          {currentQuestionIndex < questionsAsked.length && (
            <button className="b2" onClick={handleNextQuestion}>
              {currentQuestionIndex == questionsAsked.length - 1
                ? "Submit Test"
                : "Next"}
            </button>
        )}
          </div>
        </div>
      );
}

export default Technical;
