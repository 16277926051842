import React, { useState, useEffect } from "react";
import { Accordion, Dropdown } from "react-bootstrap";
import { getJsonCourseData } from "./fileUtils";
import { Link } from "react-router-dom";
import Avatar from "boring-avatars";
import ScreenSpinner from "./ScreenSpinner";
import StackedBar from "../charts/StackedBar";
import axiosInterceptor from "../utils/axiosInterceptor.ts";
import paths from "../lib/paths.ts";

const Courses = () => {
    const [courses, setCourses] = useState([]);
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState({});
    const [finishedLoading, setFinishedLoading] = useState(false);
    const [selectedView, setSelectedView] = useState("To-Do");
    const [selectedPathName, setSelectedPathName] = useState(null);
    const [universityToStudentCount, setUniversityToStudentCount] = useState(
        new Map()
    );
    const [selectedUniversity, setSelectedUniversity] = useState(null); // dummy, use user's uni
    const [selectedCourseTypes, setSelectedCourseTypes] = useState({});
    const userName = (localStorage.getItem("user_name") &&
    localStorage.getItem("user_name").trim().length > 0
        ? localStorage.getItem("user_name")
        : "User"
    ).split(" ")[0];
    const [coursePaths, setCoursePaths] = useState([]);
    const [userProgress, setUserProgress] = useState({});

    useEffect(() => {
        const loadData = async () => {
            try {
                const jsonDataArray = await getJsonCourseData();

                // const jsonDataArray = await fetchDataFromUrls(jsonUrls);
                // console.log(jsonDataArray)

                const loadedCourses = jsonDataArray.map((jsonData, i) => ({
                    id: jsonData.courseName.replace(/ /g, "-"),
                    course: jsonData.courseName,
                    courseNumber: jsonData.courseNumber || 0,
                    courseType: jsonData.courseType,
                }));
                console.log(loadedCourses);
                setCourses(loadedCourses);
                console.log("courses", courses);
                const courseTypes = [
                    ...new Set(
                        loadedCourses.map((course) => course.courseType)
                    ),
                ];
                const selectedTypes = Array.from(courseTypes).reduce(
                    (acc, type) => {
                        acc[type] = true;
                        return acc;
                    },
                    {}
                );
                setSelectedCourseTypes(selectedTypes);
                setCurrentPage(
                    courseTypes.reduce(
                        (acc, type) => ({ ...acc, [type]: 0 }),
                        {}
                    )
                );
                console.log("current page", currentPage);

                // Fetch user course progress
                const response = await axiosInterceptor.get(
                    `/course-progress/${localStorage.getItem("user_id")}/`
                );
                console.log("response", response);
                const progressData = response.data;

                console.log("Fteched");

                // Create a map of course numbers to progress
                const progressMap = progressData.reduce((acc, item) => {
                    acc[item.courseNumber] = parseFloat(item.progress);
                    return acc;
                }, {});
                setUserProgress(progressMap);
                console.log("progressMap", progressMap);
                console.log("paths", paths);

                // Update coursePaths based on the progress
                const updatedPaths = paths.map((path) => {
                    const totalUnits = path.courses.length;
                    const completedUnits = path.courses.reduce(
                        (sum, course) => {
                            const progress = progressMap[course.courseid] || 0;
                            return sum + (progress === 1 ? 1 : 0);
                        },
                        0
                    );
                    const inProgress = path.courses.some((course) => {
                        const progress = progressMap[course.courseid] || 0;
                        return progress > 0 && progress < 1;
                    });
                    return {
                        ...path,
                        totalUnits,
                        completedUnits,
                        inProgress,
                    };
                });

                console.log("updatedPaths", updatedPaths);
                setCoursePaths(updatedPaths);
            } catch (error) {
                console.error("Error loading courses:", error);
            }

            setFinishedLoading(true);
        };

        const newUniversityToStudentCount = new Map();
        for (const [pathType, students] of Object.entries(leaderboard)) {
            for (const student of students) {
                const key = `${pathType}-${student.school}`;
                newUniversityToStudentCount.set(
                    key,
                    (newUniversityToStudentCount.get(key) || 0) + 1
                );
            }
        }
        setUniversityToStudentCount(newUniversityToStudentCount);

        loadData();
    }, []);

    const selectableViews = ["To-Do", "Started", "Completed"];

    const filteredCourses = courses.filter(
        (course) => selectedCourseTypes[course.courseType] || false
    );

    const groupCoursesByType = (courses) => {
        return courses.reduce((acc, course) => {
            if (!acc[course.courseType]) {
                acc[course.courseType] = [];
            }
            acc[course.courseType].push(course);
            return acc;
        }, {});
    };
    const groupedCourses = groupCoursesByType(courses);

    filteredCourses.sort((a, b) => a.course.localeCompare(b.course));
    const courseTypes = [
        ...new Set(courses.map((course) => course.courseType)),
    ];
    courseTypes.sort();

    // Dummy data.
    const leaderboard = {
        "Data Science": [
            {
                name: "Jack Cook",
                school: "Cornell University",
                lessons: 19,
            },
            {
                name: "Christian Lizardi",
                school: "Cornell University",
                lessons: 20,
            },
            {
                name: "Simon Ilincev",
                school: "Cornell University",
                lessons: 30,
            },
            {
                name: "Nick Awertschenko",
                school: "Dartmouth University",
                lessons: 10,
            },
        ],
        "Software Engineer": [
            {
                name: "Simon Ilincev",
                school: "Cornell University",
                lessons: 30,
            },
            {
                name: "Nick Awertschenko",
                school: "Dartmouth University",
                lessons: 10,
            },
            {
                name: "Jack Cook",
                school: "Cornell University",
                lessons: 19,
            },
            {
                name: "Christian Lizardi",
                school: "Cornell University",
                lessons: 20,
            },
        ],
    };

    // More dummy data due to unimplemented backend.
    // But note that this is tied to the previous dummy data so as long as
    // that is fixed this will be as well.
    const possibleUniversities = Array.from(
        new Set(
            Object.values(leaderboard)
                .flatMap((e) => e)
                .map((e) => e.school)
        )
    );

    const getUniversityCount = (university) => {
        return (
            universityToStudentCount.get(
                `${selectedLeaderboardView}-${university}`
            ) || 0
        );
    };

    // Dummy data.
    const chartData = [
        {
            day: "Mon",
            dataScience: 2,
            softwareEngineer: 3,
            quant: 1,
        },
        {
            day: "Tue",
            dataScience: 3,
            softwareEngineer: 2,
            quant: 1,
        },
        {
            day: "Wed",
            dataScience: 2,
            softwareEngineer: 0,
            quant: 3,
        },
        {
            day: "Thu",
            dataScience: 1,
            softwareEngineer: 2,
            quant: 2,
        },
        {
            day: "Fri",
            dataScience: 3,
            softwareEngineer: 1,
            quant: 1,
        },
        {
            day: "Sat",
            dataScience: 2,
            softwareEngineer: 2,
            quant: 2,
        },
        {
            day: "Sun",
            dataScience: 1,
            softwareEngineer: 3,
            quant: 0,
        },
    ];

    // Dummy data.
    const chartConfig = {
        dataScience: {
            label: "DS",
            color: "hsl(var(--chart-1))",
        },
        softwareEngineer: {
            label: "SWE",
            color: "hsl(var(--chart-2))",
        },
        quant: {
            label: "Quant",
            color: "hsl(var(--chart-3))",
        },
    };

    const selectableLeaderboardViews = Object.keys(leaderboard);
    const [selectedLeaderboardView, setSelectedLeaderboardView] = useState(
        selectableLeaderboardViews[0]
    );

    return !finishedLoading ? (
        <ScreenSpinner />
    ) : (
        <div className="flex flex-col gap-y-8">
            <section className="flex flex-col lg:flex-row  gap-y-2 lg:gap-y-0 lg:gap-x-2 items-center lg:-mx-2">
                <div className="rounded-xl bg-white shadow-md w-full h-full row-span-9 xl:col-span-9 grid xl:grid-cols-4 gap-y-6 xl:gap-x-6 items-center place-items-center py-4 px-4 flex-grow">
                    <div className="size-40">
                        <Avatar
                            name="John Doe"
                            variant="beam"
                            size="100%"
                            colors={["#99CBFF", "#EDEDED"]}
                        />
                    </div>
                    <div className="xl:col-span-2 flex flex-col gap-y-4">
                        <h3 className="text-2xl font-medium">
                            Hi {userName}, what do you want to learn today?
                        </h3>
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search by course name"
                                className="w-full rounded-full pl-14 pr-8 py-2 bg-gray-100"
                                onChange={(e) => setFilter(e.target.value)}
                            />
                            <i className="bi bi-search absolute top-1/2 transform -translate-y-1/2 left-6 text-gray-400"></i>
                        </div>
                    </div>
                    <div
                        className={
                            "grid grid-rows-2 gap-y-4 h-min my-auto ml-10 place-self-center mx-auto text-center w-[10rem]"
                        }
                    >
                        <div className="grid grid-cols-3 gap-x-2 items-center border rounded-lg !border-black !opacity-100 py-2 px-2">
                            <div className="items-center gap-x-4 font-semibold text-4xl text-right mr-1">
                                24
                            </div>
                            <div className="text-gray-600 text-sm text-wrap col-span-2 text-left">
                                Hours completed
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-x-2 items-center border rounded-lg !border-black !opacity-100 py-2 px-2">
                            <div className="items-center gap-x-4 font-semibold text-4xl text-right mr-1">
                                30
                            </div>
                            <div className="text-gray-600 text-sm text-wrap col-span-2 text-left">
                                Courses completed
                            </div>
                        </div>
                        <div className="grid grid-cols-3 gap-x-2 items-center border rounded-lg !border-black !opacity-100 py-2 px-2">
                            <div className="items-center gap-x-4 font-semibold text-4xl text-right mr-1">
                                3
                            </div>
                            <div className="text-gray-600 text-sm text-wrap col-span-2 text-left">
                                Courses in progress
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rounded-xl bg-white shadow-md row-span-3 xl:col-span-3 place-items-center h-full items-center md:pr-10 w-full lg:w-80 justify-center my-auto flex">
                    <div className="items-center !justify-center !my-auto h-min w-full">
                        <StackedBar
                            chartConfig={chartConfig}
                            chartData={chartData}
                            showLegend={window.innerWidth >= 768}
                            xKey="day"
                        />
                    </div>
                </div>
            </section>
            <section className="flex flex-col gap-y-4">
                <div className="flex flex-row justify-between">
                    <div className="flex flex-row space-x-4">
                        {selectableViews.map((view) => (
                            <button
                                key={view}
                                className={`${
                                    selectedView === view
                                        ? "text-black"
                                        : "text-gray-400"
                                } font-semibold text-lg`}
                                onClick={() => setSelectedView(view)}
                            >
                                {view}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="grid grid-row-12 lg:grid-rows-none lg:grid-cols-12 my-auto justify-center gap-y-2 lg:gap-x-2 lg:gap-y-0 h-full !w-full">
                    <div className="row-span-7 lg:col-span-7 grid grid-cols-2 gap-2 h-min">
                        {coursePaths
                            .filter((path) =>
                                selectedView === "Started"
                                    ? path.inProgress ||
                                      (path.completedUnits > 0 &&
                                          path.completedUnits < path.totalUnits)
                                    : selectedView === "To-Do"
                                    ? path.completedUnits === 0 &&
                                      !path.inProgress
                                    : path.completedUnits === path.totalUnits
                            )
                            // .slice(0, 4)
                            .sort(
                                (a, b) =>
                                    a.completedUnits / a.totalUnits -
                                    b.completedUnits / b.totalUnits
                            )
                            .map((path, i) => (
                                <div
                                    key={i}
                                    className={
                                        "rounded-xl px-4 py-3 bg-white shadow-md space-y-2 cursor-pointer " +
                                        (selectedPathName === path.name
                                            ? "border border-3 border-opacity-100 !border-[#1B4E99]"
                                            : "")
                                    }
                                    onClick={() =>
                                        selectedPathName
                                            ? setSelectedPathName(null)
                                            : setSelectedPathName(path.name)
                                    }
                                >
                                    <div className="flex flex-row justify-between">
                                        <div className="rounded-full bg-[#426EB2] size-14 mt-2 mb-4 items-center justify-center text-white text-4xl text-center flex">
                                            <div className="text-center m-auto">
                                                {React.createElement(
                                                    path.icon,
                                                    { size: 24 }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-between">
                                        <div className="font-semibold text-lg">
                                            {path.name}
                                        </div>
                                    </div>
                                    <div className="w-full h-1 bg-gray-200 rounded-full">
                                        <div
                                            className="h-full bg-green-500 rounded-full"
                                            style={{
                                                width: `${(path.completedUnits /
                                                    path.totalUnits) *
                                                    100}%`,
                                            }}
                                        ></div>
                                        {/* add a lil circle */}
                                        <div
                                            className="h-3 w-3 bg-green-500 rounded-full -mt-2"
                                            style={{
                                                position: "relative",
                                                left: `${(path.completedUnits /
                                                    path.totalUnits) *
                                                    100 -
                                                    2}%`,
                                            }}
                                        ></div>
                                    </div>
                                    <div className="bg-gray-100 rounded-full text-xs w-min px-2 py-1 float-end">
                                        {path.completedUnits}/{path.totalUnits}
                                    </div>
                                </div>
                            ))}
                    </div>
                    <div className="row-span-5 lg:col-span-5 rounded-xl px-4 py-3 bg-white shadow-md space-y-2">
                        <div className="font-medium text-xl">
                            Leaderboard for {selectedLeaderboardView}
                        </div>
                        <div className="flex flex-row justify-between my-3">
                            <div className="flex flex-row space-x-4">
                                {selectableLeaderboardViews.map((view) => (
                                    <button
                                        key={view}
                                        className={`${
                                            selectedLeaderboardView === view
                                                ? "text-black"
                                                : "text-gray-400"
                                        } font-semibold`}
                                        onClick={() =>
                                            setSelectedLeaderboardView(view)
                                        }
                                    >
                                        {view}
                                    </button>
                                ))}
                            </div>
                            <div className="flex-grow"></div>
                            <Dropdown autoClose="outside">
                                <Dropdown.Toggle
                                    variant="ghost"
                                    className="!flex !flex-row !items-center justify-end gap-x-2 !border-none !font-medium"
                                >
                                    <div className="!mr-auto !ml-0 tracking-normal">
                                        <i class="bi bi-funnel"></i>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="!border-none mt-2 !py-0 shadow !w-max !font-medium">
                                    {possibleUniversities
                                        .sort(
                                            (a, b) =>
                                                getUniversityCount(b) -
                                                getUniversityCount(a)
                                        )
                                        .filter(
                                            (el) => getUniversityCount(el) > 0
                                        )
                                        .map((schoolName) => (
                                            <div
                                                className="!px-2.5 !py-1.5 !flex !items-center !gap-x-2.5 hover:!bg-gray-100 cursor-pointer !text-black !w-full"
                                                id={schoolName}
                                                onClick={() => {
                                                    if (
                                                        schoolName ===
                                                        selectedUniversity
                                                    ) {
                                                        setSelectedUniversity(
                                                            null
                                                        );
                                                    } else {
                                                        setSelectedUniversity(
                                                            schoolName
                                                        );
                                                    }
                                                }}
                                            >
                                                <input
                                                    type="radio"
                                                    className="special-radio"
                                                    checked={
                                                        selectedUniversity ===
                                                        schoolName
                                                    }
                                                />
                                                {schoolName} (
                                                {getUniversityCount(schoolName)}
                                                )
                                            </div>
                                        ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="flex flex-col gap-y-4">
                            {leaderboard[selectedLeaderboardView]
                                .filter(
                                    (person) =>
                                        selectedUniversity === null ||
                                        person.school === selectedUniversity
                                )
                                .map((person, i) => (
                                    <div
                                        key={i}
                                        className="flex flex-row items-center gap-x-4"
                                    >
                                        <p className="font-semibold text-lg text-black w-2 text-left">
                                            {i + 1}
                                        </p>
                                        <div className="rounded-full size-10">
                                            <Avatar
                                                name={person.name}
                                                variant="beam"
                                                size="100%"
                                                colors={["#99CBFF", "#EDEDED"]}
                                            />
                                        </div>
                                        <div className="flex flex-col gap-y-1">
                                            <div className="text-lg">
                                                {person.name}
                                            </div>
                                            <div className="text-xs text-gray-400">
                                                {person.school}
                                            </div>
                                        </div>
                                        <div className="flex-grow" />
                                        <div className="bg-gray-100 rounded-full text-nowrap text-xs px-2 py-1 float-end">
                                            +{person.lessons} lessons
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </section>
            <section
                className="rounded-xl grid grid-cols-1 lg:grid-cols-2 gap-10 space-4 bg-white shadow-md"
                style={{
                    padding: "27px",
                }}
            >
                {Object.keys(groupedCourses)
                    .filter(
                        (type) =>
                            groupedCourses[type].some((course) =>
                                course.course
                                    .toLowerCase()
                                    .includes(filter.toLowerCase())
                            ) ||
                            type.toLowerCase().includes(filter.toLowerCase())
                    )
                    .filter(
                        (type) =>
                            !selectedPathName ||
                            new Set(
                                groupedCourses[type].map(
                                    (course) => course.course
                                )
                            ).intersection(
                                new Set(
                                    paths
                                        .find(
                                            (path) =>
                                                path.name === selectedPathName
                                        )
                                        .courses.map((course) => course.name)
                                )
                            ).size > 0
                    )
                    .map((type) => (
                        <Accordion
                            key={type}
                            className="break-inside-avoid-column !border-none"
                            // defaultActiveKey="0"
                        >
                            <Accordion.Item className="w-full">
                                <Accordion.Header className="w-full focus:!bg-transparent border-[1.5px] rounded !opacity-100 p-0 !border-black">
                                    <span className="font-medium text-black">
                                        {type}
                                    </span>
                                </Accordion.Header>
                                <Accordion.Body className="!visible !bg-transparent columns-1 md:columns-2 space-y-2">
                                    {groupedCourses[type]
                                        .filter((course) =>
                                            course.course
                                                .toLowerCase()
                                                .includes(filter.toLowerCase())
                                        )
                                        .map((course, i) => (
                                            <Link
                                                key={i}
                                                className="cursor-pointer !no-underline block !font-normal hover:!underline"
                                                to={`/courses/${course.id}/lectures/1`}
                                            >
                                                {course.course}
                                            </Link>
                                        ))}
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    ))}
            </section>
        </div>
    );
};

export default Courses;
