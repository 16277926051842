import React, { useState, useEffect } from "react";
import { Modal, Button, Dropdown, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ReadMore from "../careers/ReadMore"; // Import the ReadMore component
import axiosInterceptor from "../../utils/axiosInterceptor.ts";
import ScreenSpinner from "../ScreenSpinner.jsx";

const RecruitmentPipelineManager = () => {
    const user_id = localStorage.getItem("user_id");

    const navigate = useNavigate();
    const [dataLoaded, setDataLoaded] = useState(false);
    const [loadedListings, setLoadedListings] = useState([]);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [jobToReject, setJobToReject] = useState(null);
    const [showJobProfileModal, setShowJobProfileModal] = useState(false);
    const [selectedJobForProfile, setSelectedJobForProfile] = useState(null);

    useEffect(() => {
        // First, fetch all active jobs
        axiosInterceptor
            .get(`/get_all_active_jobs/`)
            .then((response) => {
                // Then fetch the user's pipeline progress
                axiosInterceptor
                    .get(`/get_user_pipeline_progress/${user_id}/`)
                    .then((pipelineResponse) => {
                        // Combine the data from both responses
                        const updatedListings = response.data.map((job) => {
                            const pipelineProgress = pipelineResponse.data.find(
                                (progress) => progress.job_id === job.id
                            );
                            return {
                                ...job,
                                userRound: pipelineProgress
                                    ? pipelineProgress.status
                                    : "No Progress",
                            };
                        });
                        setLoadedListings(updatedListings);
                        console.log("updatedListings");
                        console.log(updatedListings);
                    })
                    .catch((pipelineError) => {
                        console.error(
                            "Error fetching pipeline progress:",
                            pipelineError
                        );
                    })
                    .finally(() => {
                        setDataLoaded(true);
                    });
            })
            .catch((error) => {
                console.error("Error fetching jobs:", error);
            });
    }, [user_id]); // Dependency on user_id

    const promptNotInterested = (jobId) => {
        setJobToReject(jobId);
        setShowConfirmationModal(true);
    };

    const confirmNotInterested = () => {
        if (jobToReject !== null) {
            // Prepare the data to update the pipeline progress status
            const updateData = {
                user_id: user_id, // Assuming you have the user's ID
                // job_id: jobToReject,
                new_status: "Rejected by Candidate",
            };

            // Make an API call to update the pipeline progress
            axiosInterceptor
                .post(`/update_pipeline_status/${jobToReject}/`, updateData)
                .then((response) => {
                    console.log("Pipeline progress updated:", response.data);
                    // Filter out the rejected job from the listings
                    const updatedListings = loadedListings.filter(
                        (job) => job.id !== jobToReject
                    );
                    setLoadedListings(updatedListings);
                })
                .catch((error) => {
                    console.error("Error updating pipeline progress:", error);
                });
        }
        setShowConfirmationModal(false);
        setJobToReject(null); // Reset the job to reject
    };

    const renderJobListings = () => {
        return loadedListings.map((job, index) => (
            <ListGroup.Item
                key={index}
                className="d-flex justify-content-between align-items-center"
            >
                <div>
                    {job.title}, {job.city}, Salary: $
                    {job.compensationLowerBound.toLocaleString()} - $
                    {job.compensationUpperBound.toLocaleString()}
                </div>
                <div className="d-flex align-items-center">
                    <div style={{ whiteSpace: "nowrap" }}>
                        Stage: {job.userRound}
                    </div>
                        <Dropdown>
                            <Dropdown.Toggle
                                variant="info"
                                style={{ marginLeft: "10px" }}
                            >
                                Actions
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    onClick={() => handleViewDescription(job.id)}
                                >
                                    View Description
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => promptNotInterested(job.id)}
                                    className="text-danger"
                                >
                                    Not Interested
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
            </ListGroup.Item>
        ));
    };

    const handleViewDescription = (jobId) => {
        const job = loadedListings.find((j) => j.id === jobId);
        if (job) {
            setSelectedJobForProfile(job);
            setShowJobProfileModal(true);
        }
    };

    const renderJobProfileModal = () => {
        if (!selectedJobForProfile) return null;

        // const { title, company, city, compensationLowerBound, compensationUpperBound, benefits, description } = selectedJobForProfile;
        console.log(selectedJobForProfile);

        return (
            <Modal
                show={showJobProfileModal}
                onHide={() => setShowJobProfileModal(false)}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedJobForProfile.title} at{" "}
                        {selectedJobForProfile.company_details.company_name}{" "}
                        Description
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="job-profile-container">
                        <div className="job-app-container">
                            <div className="job-card">
                                <p className="job-title">
                                    Job Title: {selectedJobForProfile.title}
                                </p>
                                <p className="job-company">
                                    Company:{" "}
                                    {
                                        selectedJobForProfile.company_details
                                            .company_name
                                    }
                                </p>
                                <p className="job-details">
                                    <strong>Industry:</strong>{" "}
                                    {
                                        selectedJobForProfile.company_details
                                            .industry
                                    }
                                </p>
                                <p className="job-details">
                                    <strong>City:</strong>{" "}
                                    {selectedJobForProfile.city}
                                </p>
                                <p className="job-details">
                                    <strong>Salary Range:</strong> $
                                    {selectedJobForProfile.compensationLowerBound.toLocaleString()}{" "}
                                    - $
                                    {selectedJobForProfile.compensationUpperBound.toLocaleString()}
                                </p>
                                <p className="job-details">
                                    <strong>Benefits:</strong>{" "}
                                    {selectedJobForProfile.benefits}
                                </p>
                                <p className="job-details">
                                    <strong>Company Description:</strong>
                                    <ReadMore maxLength={200}>
                                        {
                                            selectedJobForProfile
                                                .company_details
                                                .company_description
                                        }
                                    </ReadMore>
                                </p>
                                <p className="job-details">
                                    <strong>Job Description:</strong>
                                    <ReadMore maxLength={200}>
                                        {selectedJobForProfile.description}
                                    </ReadMore>
                                </p>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    };

    const goToJobMatcher = () => {
        navigate("/job-finder");
    };
    // const goToCourses = () => {
    //   navigate('/courses');
    // };

    return !dataLoaded ? (
        <ScreenSpinner />
    ) : (
        <>
            <h1 className="text-3xl font-medium text-left">
                Recruitment Progress
            </h1>
            <ListGroup className="my-4">
                {loadedListings.length > 0 ? (
                    renderJobListings()
                ) : (
                    <>
                        <div
                            className="no-information-message"
                            style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Button onClick={goToJobMatcher} variant="primary">
                                Go to Job Finder
                            </Button>
                            <p style={{ fontSize: "18px" }}>
                                No recruitment information found, indicate your
                                job interests with the Job Finder.
                            </p>
                        </div>
                    </>
                )}
            </ListGroup>
            <Modal
                show={showConfirmationModal}
                onHide={() => setShowConfirmationModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Action</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you are not interested in this job?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowConfirmationModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmNotInterested}>
                        Not Interested
                    </Button>
                </Modal.Footer>
            </Modal>
            {renderJobProfileModal()} {/* Render the job profile modal */}
        </>
    );
};

export default RecruitmentPipelineManager;
